import React from "react";
import { get, isEmpty, map } from "lodash";
import moment from "moment";

import contextType from "@skryv/core-react/src/services/contextTypes";
import {
  dossierPageWrapper as coreDossierWrapper,
  externalProps,
  internalProps,
  defaultProps,
} from "@skryv/core-react/src/components/dossier/DossierPage/DossierPage";
import { notificationTypes } from "@skryv/core-react/src/components/base/Notification/Notification";
import { getTaskForDocumentId } from "../../helpers/downloadsHelper";
import Notification from "@skryv/core-react-vo/src/components/base/Notification/Notification";
import ActiveTasks from "@skryv/core-react-vo/src/components/dossier/ActiveTasks/ActiveTasks";
import Title from "@skryv/core-react-vo/src/components/base/Title/Title";
import PageContent from "@skryv/core-react-vo/src/components/layout/Content/Content";
import contactInformation from "../../constants/contactInformation";
import documentDefinitionkeys from "../../constants/documentDefinitionKeys";
import taskDefinitionKeys from "../../constants/taskDefinitionKeys";
import DossierProgress from "@skryv/core-react-vo/src/components/dossier/DossierProgress/DossierProgress";
import Modal from "@skryv/core-react-vo/src/components/base/Modal/Modal";
import milestoneDefinitionKeys from "../../constants/milestoneDefinitionKeys";
import InfoBlock from "@skryv/core-react-vo/src/components/base/InfoBlock/InfoBlock";
import Contact from "@skryv/core-react-vo/src/components/base/Contact/Contact";
import FileDisplay from "@skryv/core-react-vo/src/components/base/FileDisplay/FileDisplay";

export function mowsubsidiesDossierWrapper() {
  class mowZeroEmissieDossier extends React.Component {
    static propTypes = { ...externalProps, ...internalProps };
    static defaultProps = defaultProps;
    static contextType = contextType;

    constructor(props) {
      super(props);

      // the contact information is static and will not change, we can set it here
      // the other state properties depend on the dossier state an will be set in the componentDidUpdate
      // for now we set them equal to the props, which contain the default values
      this.state = {
        contactInformation,
        dossierInformation: this.props.dossierInformation,
        downloads: this.props.downloads,
        notifications: this.props.notifications,
      };

      this.getDossierInformation = this.getDossierInformation.bind(this);
      this.getDownloads = this.getDownloads.bind(this);
      this.getNotifications = this.getNotifications.bind(this);
    }

    componentDidUpdate(prevProps) {
      if (this.props.dossierDetails !== prevProps.dossierDetails) {
        // the props have just been updated; so either a parent updated the props or new values came in through redux
        this.setState({
          dossierInformation: this.getDossierInformation(),
          downloads: this.getDownloads(),
          notifications: this.getNotifications(),
        });
      }
    }

    getDossierInformation() {
      const dossierInformation = [];
      if (this.props.dossier) {
        dossierInformation.push({
          value: get(this.props.dossier, "label"),
          label: this.context.gettext("Dossier label"),
        });
        dossierInformation.push({
          value: moment(get(this.props.dossier, "updatedAt")).format(
            "DD-MM-YYYY",
          ),
          label: this.context.gettext("Last update"),
        });
      }
      return dossierInformation;
    }

    getDownloads() {
      if (!this.props.dossierDetails) return [];
      const downloads = [];
      let documentId;
      documentId = this.props.getDocument(
        documentDefinitionkeys.AANMELDING_ZERO_EMISSIE,
        true,
      );
      if (documentId) {
        downloads.push(
          this.props.mapTaskToViewDownloadInfo(
            getTaskForDocumentId(
              taskDefinitionKeys.AANVRAAG,
              this.props.dossierDetails,
              documentId,
            ),
            "Aanmeldingsformulier",
          ),
        );
      }
      documentId = this.props.getDocument(
        documentDefinitionkeys.INSCHRIJVING_ZERO_EMISSIE,
        true,
      );
      if (documentId) {
        downloads.push(
          this.props.mapTaskToViewDownloadInfo(
            getTaskForDocumentId(
              taskDefinitionKeys.AANVRAAG,
              this.props.dossierDetails,
              documentId,
            ),
            "Inschrijvingsformulier",
          ),
        );
      }
      return downloads;
    }

    getNotifications() {
      let notifications = [];
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.PREMIE_AL_TOEGEKEND
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Premie al toegekend"),
            message: this.context.gettext(
              this.props.getFieldFromDocument(
                documentDefinitionkeys.CONTROLE_AANMELDING_ZERO_EMISSIE,
                ["foutmeldingCumulPremie"],
              ),
            ),
            type: notificationTypes.ERROR,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.AANMELDING_NIET_MOGELIJK_CUMUL_DRAFT
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Aanmelding niet mogelijk"),
            message: this.context.gettext(
              "U hebt al een aanmelding in opmaak, u kunt geen nieuwe aanmelding starten.",
            ),
            type: notificationTypes.ERROR,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.AANMELDING_NIET_MOGELIJK_CUMUL_AANVRAAG
      ) {
        let duplicateDossiers = this.props.getFieldFromDocument(
          documentDefinitionkeys.AANMELDING_ZERO_EMISSIE,
          ["duplicateDossiers"],
        );
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Aanmelding niet mogelijk"),
            message: this.context.gettext(
              "U hebt eerder een aanmelding gedaan in dit loket onder dossiernummer "
                .concat(
                  duplicateDossiers.substring(0, duplicateDossiers.length - 2),
                )
                .concat(
                  '. U kan maar één premie aanvragen.<br><br>Wenst u ons bijkomende informatie en/of documenten voor dit ingediende dossier te bezorgen, stuur deze dan door via ons <a href="https://mow-contact.vlaanderen.be/" target="new">contactpunt</a>. Vermeld zeker uw dossiernummer om deze informatie/documenten correct te koppelen aan uw bestaand dossier.<br><br>Wenst u dit ingediende dossier te annuleren, dan kan dit als u het inschrijvingsformulier nog niet hebt ingediend.<br><br>Is uw dossier al vervolledigd, dan vraagt u de annulatie aan via ons contactpunt. Vermeld ook dan zeker uw dossiernummer.',
                ),
            ),
            type: notificationTypes.ERROR,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.AANVRAAG_GEANNULEERD
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Aanvraag geannuleerd"),
            message: this.context.gettext("Uw aanvraag werd geannuleerd."),
            type: notificationTypes.WARNING,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.AANMELDING_INGEDIEND
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Uw aanmelding werd goed ontvangen"),
            message: this.context.gettext(
              '<p>Een dossierbehandelaar van het Departement Mobiliteit en Openbare Werken voert een eerste controle uit van de aangeleverde informatie. Als er nog informatie in de aanmelding ontbreekt, zullen we u eerst om aanvullende gegevens vragen.<br />Voor gedetailleerde informatie over het vervolg van uw aanvraag kunt u de <a href="https://www.vlaanderen.be/premie-voor-aankoop-van-een-zero-emissievoertuig" target="_blank" rel="noopener">procedure op onze website</a> raadplegen.</p>',
            ),
            type: notificationTypes.SUCCESS,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.AANMELDING_AFGEKEURD
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Uw aanmelding is niet ontvankelijk"),
            message: this.context.gettext(
              "Uw aanmelding werd gecontroleerd en niet weerhouden. De motivering voor deze beslissing werd toegelicht via e-mail.",
            ),
            type: notificationTypes.ERROR,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.INSCHRIJVING_INGEDIEND
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Uw dossier werd vervolledigd"),
            message: this.context.gettext(
              "U vervolledigde uw dossier voor een premie voor de aankoop van een zero-emissievoertuig. Uw dossier zal nu verder worden nagekeken. Indien nodig kunnen aanvullingen bij uw dossier worden opgevraagd. Voldoet uw dossier aan alle voorwaarden, dan wordt uw aanvraag goedgekeurd en zal de premie uitbetaald worden. U wordt via e-mail geïnformeerd over de beslissing.",
            ),
            type: notificationTypes.SUCCESS,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.AANVRAAG_AFGEKEURD
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext(
              "Uw aanvraag voor een premie voor de aankoop van zero-emissievoertuig is afgekeurd.",
            ),
            message: this.context.gettext(
              "Uw aanvraag werd gecontroleerd en niet weerhouden. De motivering voor deze beslissing werd toegelicht via e-mail.",
            ),
            type: notificationTypes.ERROR,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
          milestoneDefinitionKeys.ONTBREKENDE_INFO &&
        this.props.getTask(taskDefinitionKeys.EXTRA_INFO, true) !== undefined
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Ontbrekende info"),
            message: this.context
              .gettext(
                "Gelieve bijkomende informatie aan te leveren voor uw dossier: ",
              )
              .concat("<br>")
              .concat(
                this.props.getFieldFromDocument(
                  documentDefinitionkeys.ONTBREKENDE_INFO_ZERO_EMISSIE,
                  ["beschrijfOntbrekendeInfoAanmelding", "value"],
                ) !== undefined
                  ? this.props.getFieldFromDocument(
                      documentDefinitionkeys.ONTBREKENDE_INFO_ZERO_EMISSIE,
                      ["beschrijfOntbrekendeInfoAanmelding", "value"],
                    )
                  : "",
              )
              .concat("<br>")
              .concat(
                this.props.getFieldFromDocument(
                  documentDefinitionkeys.ONTBREKENDE_INFO_ZERO_EMISSIE,
                  ["beschrijfOntbrekendeInfoInschrijving", "value"],
                ) !== undefined
                  ? this.props.getFieldFromDocument(
                      documentDefinitionkeys.ONTBREKENDE_INFO_ZERO_EMISSIE,
                      ["beschrijfOntbrekendeInfoInschrijving", "value"],
                    )
                  : "",
              )
              .concat(
                "<br> Dit kunt u doen door uw initiële dossier aan te passen via 'Pas aanmelding aan - Uitvoeren.' of 'Pas inschrijving aan - Uitvoeren.'",
              ),
            type: notificationTypes.WARNING,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.AANVRAAG_VOLLEDIG
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Aanvraag in behandeling"),
            message: this.context.gettext(
              "Uw aanvraag is volledig en wordt verder behandeld.",
            ),
            type: notificationTypes.SUCCESS,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.ONTBREKENDE_INFO_INSCHRIJVING_OPGELADEN
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Ontbrekende informatie opgeladen"),
            message: this.context.gettext(
              "We hebben uw documenten goed ontvangen en zullen uw dossier verder in behandeling nemen.",
            ),
            type: notificationTypes.SUCCESS,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.ONTBREKENDE_INFO_AANMELDING_OPGELADEN
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Ontbrekende informatie opgeladen"),
            message: this.context.gettext(
              "We hebben uw documenten goed ontvangen en zullen uw dossier verder in behandeling nemen.",
            ),
            type: notificationTypes.SUCCESS,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.AANMELDING_NIET_MOGELIJK_VANAF_1_JANUARI
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Aanmelding niet mogelijk"),
            message: this.context.gettext(
              "Vanaf 1 januari, kunnen er geen nieuwe dossiers worden aangemaakt voor de premie van zero-emissie voertuigen.",
            ),
            type: notificationTypes.WARNING,
          },
        ];
      }
      if (
        this.props.getLatestMilestoneKey() ===
        milestoneDefinitionKeys.AANMELDING_INDIENEN_NIET_MOGELIJK_VANAF_1_JANUARI
      ) {
        notifications = [
          {
            id: this.props.dossier.id,
            title: this.context.gettext("Aanmelding niet mogelijk"),
            message: this.context.gettext(
              "Vanaf 1 januari, kunnen er geen nieuwe dossiers worden aangemaakt voor de premie van zero-emissie voertuigen.",
            ),
            type: notificationTypes.WARNING,
          },
        ];
      }

      return notifications;
    }

    render() {
      // override some default props with props that are derived from latest version of the dossierDetails
      const componentsToPass = {
        ...this.props,
        contactInformation: this.state.contactInformation,
        dossierInformation: this.state.dossierInformation,
        downloads: this.state.downloads,
        notifications: this.state.notifications,
      };

      return <MowZeroEmissieDossierPage {...componentsToPass} />;
    }
  }

  return mowZeroEmissieDossier;
}

class MowZeroEmissieDossierPage extends React.Component {
  static propTypes = { ...externalProps, ...internalProps };
  static defaultProps = defaultProps;
  static contextType = contextType;

  renderTitle() {
    return (
      <div className="dossier-title vl-col--1-1">
        <Title level={1} title={this.props.dossier.label} />
      </div>
    );
  }

  renderDossierProgress() {
    const steps = this.props.getDossierProgressSteps();
    return steps && <DossierProgress steps={steps} />;
  }

  renderNotification() {
    return (
      this.props.notifications &&
      this.props.notifications.length > 0 && (
        <div className="dossier-notifications vl-col--1-1">
          {map(this.props.notifications, (notification, key) => (
            <Notification
              notification={notification}
              class="dossier-notification"
              key={key}
            />
          ))}
        </div>
      )
    );
  }

  renderActiveTasks() {
    if (this.props.loadingDossierDetails) {
      return (
        <p>{this.context.gettext("Loading new dossier information...")}</p>
      );
    }

    const activeTasks = this.props.getMyActiveTasksInDossier();
    return (
      activeTasks &&
      activeTasks.length > 0 && (
        <div className="dossier-active-tasks vl-col--1-1">
          <Title
            level={2}
            title="Te nemen dossier acties"
            iconClass="vl-vi-file-tasks-check"
          />
          <ActiveTasks
            activeTasks={activeTasks}
            execute={this.props.executeTask}
          />
        </div>
      )
    );
  }

  renderEnabledTasks() {
    const { enabledTasks } = this.props;

    if (!enabledTasks || isEmpty(enabledTasks)) return null;

    function mapActivityToTask(activity) {
      return {
        id: activity.id,
        name: activity.activityName,
        description: activity.activityDescription,
      };
    }

    const mappedEnabledTasks = map(enabledTasks, mapActivityToTask);

    return (
      <div className="dossier-title vl-col--1-1">
        <Title
          level={2}
          title="Optionele dossier acties"
          iconClass="vl-vi-cursor-hand"
        />
        <ActiveTasks
          activeTasks={mappedEnabledTasks}
          execute={this.props.handleEnabledTaskSelect}
        />
      </div>
    );
  }

  renderDossierInformation() {
    return (
      this.props.dossierInformation &&
      this.props.dossierInformation.length > 0 && (
        <div className="dossier-information vl-col--1-1">
          <section className="vl-infoblock">
            <Title
              level={2}
              title={this.context.gettext("Dossier details")}
              iconClass="vl-vi-news"
            />
            <InfoBlock
              infoBlockData={this.props.dossierInformation}
              columnsNumber={this.props.dossierInformationNbColumns}
            />
          </section>
        </div>
      )
    );
  }

  renderDownloads() {
    return (
      this.props.downloads &&
      this.props.downloads.length > 0 && (
        <div className="dossier-downloads vl-col--1-1">
          <section className="vl-infoblock">
            <Title
              level={2}
              title={this.context.gettext("Documents and Downloads")}
              iconClass="vl-vi-file-download"
            />
            <FileDisplay downloads={this.props.downloads} />
          </section>
        </div>
      )
    );
  }

  renderActivateTaskWarningModal() {
    return (
      <Modal
        open={this.props.shouldShowActivateTaskWarningModal}
        text={this.context.gettext(
          "Ben je zeker dat je deze actie wilt uitvoeren?",
        )}
        title={`${this.context.gettext("Voer")} ${(
          this.props.modalTask.activityName || ""
        ).toLowerCase()} ${this.context.gettext("uit")}`}
        yesText={this.context.gettext("Yes")}
        noText={this.context.gettext("No")}
        yes={() => this.props.toEnabledTask(this.props.modalTask.id)}
        no={() => this.props.cancelActivationOfEnabledTask()}
        modalIdentifier={this.props.modalTask.id || "default"}
      />
    );
  }

  renderContactInformation() {
    return (
      this.props.contactInformation && (
        <div className="dossier-contact vl-col--1-1">
          <section className="vl-infoblock">
            <Title
              level={2}
              title={this.context.gettext("Contact")}
              iconClass="vl-vi-chat"
            />
            <Contact contactInformation={this.props.contactInformation} />
          </section>
        </div>
      )
    );
  }

  render() {
    return (
      <PageContent contentName="dossier">
        {this.renderDossierProgress()}
        <div className="dossier-page-header">
          <div className="dossier-page-header-title">
            {this.props.shouldShowTitle && this.renderTitle()}
          </div>
        </div>
        {this.renderActivateTaskWarningModal()}
        {this.props.shouldShowNotifications && this.renderNotification()}
        {this.props.shouldShowActiveTasks && this.renderActiveTasks()}
        {this.renderEnabledTasks()}
        {this.props.shouldShowDossierInformation &&
          this.renderDossierInformation()}
        {this.props.shouldShowDownloads && this.renderDownloads()}
        {this.props.shouldShowContactInformation &&
          this.renderContactInformation()}
      </PageContent>
    );
  }
}

export default coreDossierWrapper(mowsubsidiesDossierWrapper());
